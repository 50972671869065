<template>
  <form @submit.prevent="post" class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">Edit User</h1>
        <router-link to="/partners/accounts" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </router-link>
      </div>
    </section>
    <hr class="mt-15" />

    <section class="w600 mx-auto mb-20">
      <div class="center py-20 col gap-20">
        <div class="w600 pt-10 weight-300">Edit user account details</div>
        <div class="grid align-center mt-20">
          <div class="font-14 opacity-60">Company Name</div>
          <s-text-field v-model="companyName" height="30"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.account.first_name") }}
          </div>
          <s-text-field v-model="firstName" height="30"></s-text-field>
          <div class="font-14 opacity-60">
            {{ $t("modal.account.last_name") }}
          </div>
          <s-text-field v-model="lastName" height="30"></s-text-field>
          <div class="font-14 opacity-60">Email</div>
          <s-text-field v-model="email" height="30"></s-text-field>
          <div class="font-14 opacity-60">Role</div>
          <s-text-field v-model="role" height="30"></s-text-field>
          <div class="font-14 opacity-60">Hash</div>
          <s-text-field v-model="hash" height="30"></s-text-field>
          <div class="font-14 opacity-60">Password</div>
          <s-text-field
            v-model="password"
            type="password"
            password
            height="30"
          ></s-text-field>
        </div>
      </div>
    </section>

    <section class="center py-20 col gap-20">
      <div class="w600 justify-end">
        <s-btn class="green">SAVE</s-btn>
      </div>
    </section>
  </form>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      hash: "",
      role: "",
      password: "",
    };
  },
  methods: {
    async post() {
      let res = await this.$store.dispatch("accounts/putUser", this._data);
      this.$router.push("/partners/accounts");
    },
  },
  computed: {
    ...mapState("accounts", ["user"]),
  },
  watch: {},
  mounted() {
    this.companyName = this.user.companyName
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email;
    this.hash = this.user.hash;
    this.role = this.user.role;
    this.password = this.user.password;
  },
};
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  max-width: 600px;
  width: 100%;
}
</style>